<template>
    <v-main app>

        <div class="home--banner" v-if="showBanner">
            <v-img
                v-if="$vuetify.breakpoint.xsOnly"
                :key="'bannerMobile'"
                :src="getBannerMobileRoute() + getCompany.banner_mobile"
                contain
                min-width="100vw"
            />
            <v-img
                :key="'banner'"
                v-else
                :src="getBannerRoute() + getCompany.banner"
                contain
                min-width="100vw"
            />
        </div>
        <!-- Provides the application the proper gutter -->

        <!-- fill-height class add for center -->
        <!-- <v-skeleton-loader
      v-if="getThemeMode.isLoading"
      :loading="getThemeMode.isLoading"
      height="100vh"
      width="100%"
      color="primary"
      type="table-heading, list-item-two-line,list-item-two-line, image, table-tfoot,list-item-avatar-three-line, image, article,table-heading, list-item-two-line,list-item-two-line, image, table-tfoot,list-item-avatar-three-line, image, article"
    ></v-skeleton-loader>-->
        <v-container class="pt-6 main-view-area" style="min-height: 10vh">
            <!-- If using vue-router -->

            <!-- <customizer /> -->
            <v-expand-transition mode="out-in">
                <router-view />
            </v-expand-transition>
        </v-container>
        <div class="flex-grow-1" />
        <app-footer />
    </v-main>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    components: {
        AppFooter: () => import('./Footer')
        // Customizer: () => import("@/layouts/common-drawer/Customizer"),
    },
    computed: {
        showBanner(){
            return (this.$route.path === '/app/pages/Home');
        },
        ...mapGetters(["getThemeMode", "getCompany"]),

        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        }
    },
    methods:{
        getBannerRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=companies/images/banner/';
        },
        getBannerMobileRoute() {
            return process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=companies/images/banner_mobile/';
        }
    }
}
</script>

<style lang="scss">
.v-main__wrap {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.home--banner{
    align-self: start;
    width: 100vw;
    height: fit-content;
}
</style>
